/**
 * Button's center align (absolute positioning)
 */
 body {
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.content {
	width: 100%;
	height: 100%;
	/*width: 400px;*/
	/*height: 400px;*/
	background-color: red;
    display: table;
    position: absolute;
}
.button-wrapper {
	background-color: navy;
    display: table-cell;
    vertical-align: middle;
}

.button {
	display: block;
	vertical-align: middle;
	margin: auto;
	width: 300px;
	height: 100px;
}

.apple-pay-button {
    display: inline-block;
    background-color: #000;
    border-radius: 5px;
    padding: 10px 16px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    width:200px;
    display: flex;
  justify-content: center;
  align-items: center;
  }
  
.apple-pay-button:hover {
    opacity: 0.8;
  }

  .responsive-image {
    max-width: 50%;
    height: auto;
    display: block;
    margin: auto;
  }

  .centered-div {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // border: 1px solid #ccc;
  }

  .center-items{
   
    align-items: center;
  }

  .apple-pay-footer{
    background-color: #ff6600;
    height: 100px;
  }

  .apple-pay-container{
    // margin-top: 100px;
    max-width: 400px;
  }